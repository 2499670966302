<template>
  <v-card class="add-user">
    <v-card-title>{{ edit ? "Edit" : "Add" }} User</v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="isValid">
        <v-row>
          <v-col md="6" cols="12">
            <v-text-field
              v-model="form.first_name"
              :rules="[rules.requiredRule]"
              color="secondary"
              label="First Name"
            >
            </v-text-field>
          </v-col>

          <v-col md="6" cols="12">
            <v-text-field
              v-model="form.last_name"
              :rules="[rules.requiredRule]"
              color="secondary"
              label="last Name"
            >
            </v-text-field>
          </v-col>

          <v-col md="6" cols="12">
            <v-text-field
              v-model="form.email"
              :rules="[rules.requiredRule, rules.emailRule]"
              color="secondary"
              label="Email"
            >
            </v-text-field>
          </v-col>

          <v-col md="6" cols="12">
            <v-text-field
              v-model="form.contact"
              :rules="[rules.requiredRule]"
              color="secondary"
              label="Contact Number"
            >
            </v-text-field>
          </v-col>

          <v-col md="6" cols="12">
            <v-select
              v-model="form.company"
              :rules="[rules.requiredRule]"
              :items="companiesList"
              item-text="label"
              item-value="id"
              color="secondary"
              label="Company"
            >
            </v-select>
          </v-col>

          <v-col md="6" cols="12">
            <v-select
              v-model="form.role"
              :rules="[rules.requiredRule]"
              :items="userRoles"
              item-text="label"
              item-value="value"
              color="secondary"
              label="User role"
            >
            </v-select>
          </v-col>

          <v-col md="6" cols="12">
            <v-text-field
              v-model="form.password"
              :rules="[rules.requiredRule]"
              color="secondary"
              label="User password"
            >
            </v-text-field>
          </v-col>

          <v-col cols="12">
            <ImagePicker
              :src="form.avatar"
              placeholder="Upload User Photo"
              @update="imageHandler"
              @remove="imageRemoveHandler"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn color="error" @click="$emit('close')">Close</v-btn>
      <v-btn color="secondary" @click="formHandler"
        >{{ edit ? "Update" : "Create" }} User</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { requiredRule, emailRule } from "../../helpers/form-rules";
import { COMPANY_USER_ROLES } from "@/constants";
import ImagePicker from "../image-picker/image-picker.vue";

export default {
  name: "admin-add-user",
  props: {
    edit: { type: Boolean, default: false },
  },
  components: { ImagePicker },
  data() {
    return {
      companies: [],
      isValid: false,
      rules: {
        requiredRule,
        emailRule,
      },
      form: {
        first_name: null,
        last_name: null,
        email: null,
        contact: null,
        company: null,
        role: null,
        password: null,
        avatar: null,
      },
      avatarFile: null,
    };
  },
  computed: {
    userRoles() {
      return COMPANY_USER_ROLES;
    },
    companiesList() {
      return this.companies.map((company) => {
        const { name, _id } = company;
        return {
          id: _id,
          label: name,
        };
      });
    },
  },
  methods: {
    imageHandler({ file, src }) {
      this.avatarFile = file;
      this.form.avatar = src;
    },
    imageRemoveHandler() {
      this.avatarFile = null;
      this.form.avatar = null;
    },
    async formHandler() {
      await this.$refs.form.validate();
      if (this.isValid) {
        const payload = {
          phone: this.form.contact,
          first_name: this.form.first_name,
          last_name: this.form.last_name,
          role: this.form.role,
          password: this.form.password,
          company_id: this.form.company,
          email: this.form.email,
        };
        let response = await this.axios.post("/user", payload);
        if (response.status === 200) {
          this.$notify({
            group: "success",
            type: "success",
            title: "Success",
            text: "User created successfully!",
          });
          this.$emit("close");
        } else {
          this.$notify({
            group: "backend-error",
            type: "error",
            title: "Validation Error",
            text: "Something went wrong. Please try again later.",
          });
        }
      } else {
        this.$notify({
          group: "backend-error",
          type: "error",
          title: "Validation Error",
          text: "Some fields are empty",
        });
      }
    },
    async fetchCompanies() {
      try {
        this.loading = true;
        const resp = await this.axios.get(`/company`);
        const { data } = resp.data;
        this.companies = data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    this.fetchCompanies();
  },
};
</script>

<style lang="scss" scoped>
.add-user {
  &::v-deep .v-input {
    margin-top: 0;
    padding-top: 0;
  }
}
</style>
