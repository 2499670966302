<template>
  <v-card>
    <v-card-title
      >{{ $vuetify.lang.t(`$vuetify.${edit ? "edit" : "add"}`) }}
      {{ $vuetify.lang.t("$vuetify.candidate") }}</v-card-title
    >
    <v-card-text>
      <v-form
        v-model="applicationForm"
        style="width: 100%"
        @submit.prevent="candidateHandler"
      >
        <v-flex>
          <v-layout xs12>
            <v-flex xs6 class="pa-1">
              <v-text-field
                v-model="user.first_name"
                :label="$vuetify.lang.t('$vuetify.first_name')"
                :rules="fieldRules"
                required
                class="mr-2"
              >
              </v-text-field>
            </v-flex>
            <v-flex xs6 class="pa-1">
              <v-text-field
                v-model="user.last_name"
                :label="$vuetify.lang.t('$vuetify.last_name')"
                :rules="fieldRules"
                required
              >
              </v-text-field>
            </v-flex>
          </v-layout>
        </v-flex>

        <v-flex>
          <v-layout xs12>
            <v-flex xs6 class="pa-1">
              <v-text-field
                v-model="user.phone"
                :label="$vuetify.lang.t('$vuetify.phone_number')"
                :rules="fieldRules"
                required
                class="mr-2"
              >
              </v-text-field>
            </v-flex>
            <v-flex xs6 class="pa-1">
              <v-text-field
                v-model="user.email"
                :label="$vuetify.lang.t('$vuetify.email')"
                :rules="emailRules"
                required
              >
              </v-text-field>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex>
          <v-layout xs12>
            <v-flex xs6 class="pa-1">
              <v-autocomplete
                v-model="user.jobpostid"
                :items="formatedJobs"
                :item-text="'title'"
                :item-value="'_id'"
                class="mr-2"
                :label="$vuetify.lang.t('$vuetify.position_title')"
                required
                :loading="jobsLoading"
                :menu-props="{ zIndex: 210, overflowY: true }"
              >
              </v-autocomplete>
            </v-flex>
            <v-flex xs6 class="pa-1">
              <v-text-field
                v-model="user.experience"
                :label="$vuetify.lang.t('$vuetify.experience')"
                :rules="fieldRules"
                required
              >
              </v-text-field>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex style="min-height: 120px">
          <v-layout xs12>
            <v-flex
              xs6
              class="ma-1"
              @dragover.prevent="dragOver"
              @dragleave.prevent="dragLeave"
              @drop.prevent="(e) => drop(e, 'cv')"
            >
              <p class="my-1">{{ $vuetify.lang.t("$vuetify.cv_resume") }} *</p>
              <div class="dropbox image-upload text-sm-center">
                <label for="file-input">
                  <v-icon class="pointer" color="secondary" slot="activator"
                    >mdi-cloud-upload</v-icon
                  >
                  <h3 class="text-lightblue pointer">
                    {{ $vuetify.lang.t("$vuetify.click_here") }}
                  </h3>
                </label>

                <input
                  @change="setFile('cv')"
                  ref="cv_file"
                  id="file-input"
                  type="file"
                />
              </div>
              <div v-if="cv_name" class="file-uploaded my-2 cv">
                <v-icon class="pdf_icon">mdi-file-pdf-box</v-icon>
                {{ cv_name | limitChar }}
                <v-btn icon @click="remove('cv')"
                  ><v-icon>mdi-close-circle-outline</v-icon></v-btn
                >
              </div>
            </v-flex>
            <v-flex
              xs6
              class="ma-1"
              @dragover.prevent="dragOver"
              @dragleave.prevent="dragLeave"
              @drop.prevent="(e) => drop(e, 'cl')"
            >
              <p class="my-1">Cover Letter</p>
              <div class="dropbox image-upload text-sm-center">
                <label for="file-cl">
                  <v-icon class="pointer" color="secondary" slot="activator"
                    >mdi-cloud-upload</v-icon
                  >
                  <h3 class="text-lightblue pointer">
                    {{ $vuetify.lang.t("$vuetify.click_here") }}
                  </h3>
                </label>

                <input
                  @change="setFile('cl')"
                  id="file-cl"
                  ref="coverLetter_file"
                  type="file"
                />
              </div>
              <div v-if="coverLetter" class="file-uploaded my-2 cl">
                <v-icon class="pdf_icon">mdi-file-pdf-box</v-icon>
                {{ coverLetter | limitChar }}
                <v-btn icon @click="remove('cl')"
                  ><v-icon>mdi-close-circle-outline</v-icon></v-btn
                >
              </div>
            </v-flex>
          </v-layout>
        </v-flex>

        <div class="mt-10 text-right">
          <v-btn
            outlined
            @click="$emit('close-popup')"
            class="buttonPrimary text-lightblue mr-5"
            >{{ $vuetify.lang.t("$vuetify.cancel") }}</v-btn
          >

          <v-btn
            type="submit"
            color="secondary"
            class="mr-3"
            :loading="loading"
            >{{
              $vuetify.lang.t(
                `$vuetify.${edit ? "save_changes" : "create_user"}`
              )
            }}</v-btn
          >
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
// import * as _ from "lodash";
import moment from "moment";
import { mapActions, mapState } from "vuex";
export default {
  name: "edit-candidate",
  props: {
    application: {
      type: Object,
    },
  },

  data() {
    return {
      applicationForm: false,
      edit: false,
      user: {},
      loading: false,
      cities: ["Islamabad", "Karachi", "Lahore", "UAE", "Qatar"],
      cv_name: "",
      coverLetter: "",
      error_message: "",
      alert: false,
      jobsLoading: false,
      emailRules: [(v) => !!v || "E-mail is required"],
      fieldRules: [(v) => !!v || "Field is required"],
    };
  },
  mounted() {
    this.getJobs();
    this.setUserData();
  },
  computed: {
    ...mapState(["jobs"]),
    formatedJobs() {
      return this.jobs.map((job) => {
        const { title, created_at } = job;
        return {
          ...job,
          title: `${title}  (${moment(created_at).format("l")})`,
        };
      });

      // moment
    },
  },
  methods: {
    ...mapActions(["FETCH_JOBS"]),
    // drag-drop-functionality
    dragOver() {
      console.log("dragover");
    },
    dragLeave() {
      console.log("DRAG_LEAVE");
    },
    drop(event, type) {
      let file = event.dataTransfer.files[0];
      if (!file) return;

      if (type == "cv") {
        this.user.cv = file;
        this.cv_name = file.name;
      }
      if (type == "cl") {
        this.user.coverLetter = file;
        this.coverLetter = file.name;
      }
    },
    setUserData() {
      if (this.application) {
        this.edit = true;
        this.user = this.application.candidate
          ? this.application.candidate
          : {};
        this.user.jobpostid = this.application.job_post_id;
        this.user.status = this.application.status;
        this.user._id = this.application._id;
        this.coverLetter = this.application.cover_letter;
        this.user.coverLetter = this.coverLetter;
        this.user.cv = this.application.cv;
        this.cv_name = this.user.cv;
      }
    },
    async getJobs() {
      this.jobsLoading = true;
      await this.FETCH_JOBS();
      this.jobsLoading = false;
    },
    setFile(type) {
      if (type == "cv") {
        this.user.cv = this.$refs.cv_file.files[0];
        this.cv_name = this.$refs.cv_file.files[0].name;
      }
      if (type == "cl") {
        this.user.coverLetter = this.$refs.coverLetter_file.files[0];
        this.coverLetter = this.$refs.coverLetter_file.files[0].name;
      }
    },
    remove(type) {
      if (type == "cv") {
        this.user.cv = null;
        this.cv_name = null;
      }
      if (type == "cl") {
        this.user.coverLetter = null;
        this.coverLetter = null;
      }
    },
    candidateHandler() {
      if (this.edit) {
        this.editCandidate();
      } else {
        this.addCandidate();
      }
    },
    async addCandidate() {
      if (!this.applicationForm) {
        this.$notify({
          group: "backend-error",
          title: "Error",
          text: "Please Fill all the Info",
          type: "error",
          duration: 5000,
        });
        return;
      }

      if (!this.user.cv) {
        this.$notify({
          group: "backend-error",
          title: "Error",
          text: "Please Upload the Candidate CV",
          type: "error",
          duration: 5000,
        });
        return;
      }

      try {
        this.loading = true;
        const payload = this.setData();
        await this.axios.post("application", payload);
        this.$emit("close-popup");
        this.$notify({
          group: "backend-error",
          title: "Success",
          text: "A new user created successfully...",
          type: "success",
          duration: 10000,
        });
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    async editCandidate() {
      try {
        this.loading = true;
        const payload = this.setData();
        this.user.job_post_id = this.user.jobpostid;
        this.user.cover_letter = this.user.coverLetter;
        await this.axios.put(
          `/candidate/${this.application.candidate_id}`,
          this.user
        );

        await this.axios.post(
          `update/application/${this.application._id}`,
          payload,
          {
            headers: {
              "content-type": "multipart/form-data",
            },
          }
        );

        this.$emit("refresh");
        this.$emit("close-popup");

        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    setData() {
      const data = new FormData();
      data.append("first_name", this.user.first_name);
      data.append("last_name", this.user.last_name);
      data.append("phone", this.user.phone);
      data.append("email", this.user.email);
      data.append("cv", this.user.cv);
      data.append("experience", this.user.experience);
      data.append("coverLetter", this.user.coverLetter);
      data.append("jobPostId", this.user.jobpostid);
      data.append("source", this.$store.state.user.full_name);
      data.append("emp_id", this.$store.state.user._id);
      data.append("platform", "email");
      return data;
    },
  },
};
</script>

<style lang="scss">
.dropbox {
  border: 2px dashed #829299;
  background-color: #f7f7f7;
  min-height: 100px;
  max-height: 120px;
}
.image-upload > input {
  display: none;
}
.image-upload {
  margin: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pointer {
  cursor: pointer;
}
.file-uploaded {
  justify-content: space-between;
  display: flex;
  align-items: center;
  .pdf_icon {
    font-size: 40px;
    color: #da2c2c;
  }
}
</style>
