<template>
  <div class="relative">
    <Header />
    <Sidebar />
    <v-main>
      <v-container fluid>
        <AdminBtn v-if="user && user.role === 0" />
        <router-view></router-view>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import Header from "@/layouts/components/header";
import Sidebar from "@/layouts/components/sidebar";
import AdminBtn from "../components/system-admin/admin-btn.vue";
import { mapState } from "vuex";
export default {
  name: "DashboardLayout",
  components: {
    Header,
    Sidebar,
    AdminBtn,
  },
  computed: {
    ...mapState(["user"]),
  },
};
</script>

<style lang="scss" scoped>
.relative {
  position: relative;
}
</style>
