<template>
  <v-navigation-drawer
    v-model="drawer"
    fixed
    app
    :mini-variant="false"
    :clipped="false"
    color="primary"
    class="app-side-bar"
    :style="`z-index:4;padding-top:${headerExpansion && isAdmin ? 120 : 65}px;`"
    :right="$vuetify.rtl"
  >
    <v-list dark>
      <template v-for="(item, i) in items">
        <v-list-item v-if="!item.children" :key="i" :to="item.to" router>
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title v-text="item.title" />
          </v-list-item-content>
        </v-list-item>

        <!-- has children -->
        <v-list-group v-else :key="i + 1" :value="false">
          <template v-slot:activator>
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-text="item.title" />
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="(child, i) in item.children"
            :to="child.to"
            :key="i"
            link
            router
          >
            <v-list-item-action>
              <!-- <v-icon>{{ child.icon }}</v-icon> -->
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-text="child.title" />
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </template>
    </v-list>

    <div class="sidebar-left-content">
      <ul class="sidebar-tabs">
        <li :key="index" v-for="(link, index) in profileLinks">
          <span @click="removeLink(index)"><i class="fa fa-minus"></i></span>
          <router-link
            class="caption white--text decoration-none"
            :to="{
              name: 'applicant-profile',
              params: { id: link.id },
            }"
          >
            {{ link.name }}
          </router-link>
        </li>
      </ul>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import userRestriction from "@/mixins/user-restriction.js";
export default {
  name: "SideBar",
  mixins: [userRestriction],
  data() {
    return {};
  },
  computed: {
    ...mapState(["profileLinks", "headerExpansion", "user"]),
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(value) {
        this.$store.commit("SET_DRAWER", value);
      },
    },
    items() {
      if (!this.user) return [];
      const { role } = this.user;
      return [
        {
          icon: "mdi-view-dashboard",
          title: this.$vuetify.lang.t("$vuetify.dashboard"),
          to: "/",
          roles: [1, 2, 3, 4],
        },
        {
          icon: "mdi-account-circle",
          title: this.$vuetify.lang.t("$vuetify.applicants"),
          to: "/application",
          roles: [1, 2, 3, 4],
        },
        {
          icon: "mdi-note-text-outline",
          title: this.$vuetify.lang.t("$vuetify.posted_jobs"),
          to: "/posted-jobs",
          roles: [1, 2, 3, 4],
        },
        // {
        //   icon: "mdi-note-text-outline",
        //   title: this.$vuetify.lang.t("$vuetify.auto_interviews"),
        //   to: "/interview-results",
        //   roles: [1, 2, 3, 4]
        // },
        {
          icon: "mdi-note-text-outline",
          title: this.$vuetify.lang.t("$vuetify.auto_interviews"),
          to: "/automated-interviews",
          roles: [1, 2, 3, 4],
        },
        {
          icon: "mdi-trophy",
          title: this.$vuetify.lang.t("$vuetify.leaderboard"),
          to: "/leaderboard",
          roles: [1, 2, 3, 4],
        },
        {
          icon: "mdi-cog-outline",
          title: this.$vuetify.lang.t("$vuetify.settings"),
          roles: [1, 2],
          children: [
            {
              icon: "mdi-account-circle",
              title: this.$vuetify.lang.t("$vuetify.users"),
              to: "/setting/users",
            },
            {
              icon: "mdi-account-circle",
              title: this.$vuetify.lang.t("$vuetify.interview_stages"),
              to: "/setting/interview-stages",
            },
            {
              icon: "mdi-account-circle",
              title: this.$vuetify.lang.t("$vuetify.templates"),
              to: "/setting/templates",
            },
            {
              icon: "mdi-account-circle",
              title: "Interview Questions",
              to: "/setting/interview-questions",
            },
            // {
            //   icon: "mdi-account-circle",
            //   title: this.$vuetify.lang.t("$vuetify.company"),
            //   to: "/setting/company",
            // },
          ],
        },
        // system admin routes
        {
          icon: "mdi-view-dashboard",
          title: this.$vuetify.lang.t("$vuetify.dashboard"),
          to: "/system-admin/dashboard",
          roles: [0],
        },
        {
          icon: "mdi-account-circle",
          title: this.$vuetify.lang.t("$vuetify.user"),
          to: "/system-admin/users",
          roles: [0],
        },
        {
          icon: "mdi-trophy",
          title: this.$vuetify.lang.t("$vuetify.company"),
          to: "/system-admin/company",
          roles: [0],
        },
        {
          icon: "mdi-trophy",
          title: this.$vuetify.lang.t("$vuetify.location"),
          to: "/system-admin/location",
          roles: [0],
        },
        {
          icon: "mdi-trophy",
          title: this.$vuetify.lang.t("$vuetify.packages"),
          to: "/system-admin/packages",
          roles: [0],
        },
      ].filter((item) => item.roles.includes(+role));
    },
  },
  methods: {
    ...mapMutations(["SET_PROFILE_LINKS"]),
    removeLink(index) {
      const links = [...this.profileLinks];
      links.splice(index, 1);
      this.SET_PROFILE_LINKS(links);
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar-tabs {
  margin: 2em 0 0 0;
  padding: 0;
  list-style-type: none;

  li {
    background-color: #333;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    color: #fff;
    padding: 10px 0;
    margin-bottom: 1.5em;
    border-radius: 30px;
    font-size: 0.9em;

    span {
      width: 44px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 0;
      border-radius: 50%;
      background-color: #fff;
      color: #333;
      cursor: pointer;

      &:hover {
        background-color: #3dade5;
        color: #fff;
      }
    }

    a {
      text-decoration: none;

      &.router-link-exact-active {
        font-weight: bold;
        font-size: 1rem !important;
      }
    }
  }
}

.app-side-bar {
  &::v-deep .v-list .v-list-item--active {
    color: #fff;
  }
}
</style>
