<template>
  <div
    class="img-container"
    @dragover.prevent="dragOver"
    @dragleave.prevent="dragLeave"
    @drop.prevent="drop"
  >
    <input
      type="file"
      ref="imagePicker"
      class="d-none"
      @change="getImage"
      :multiple="multiple"
      accept="image/*"
    />

    <div
      class="empty-container"
      v-if="!hasImageSrc"
      @click="$refs.imagePicker.click()"
    >
      {{ placeholder }}
    </div>
    <div v-else class="preview-img">
      <img
        :src="hasImageSrc"
        :style="`max-height:${maxHeight}`"
        alt="logo"
        @click="$refs.imagePicker.click()"
      />

      <v-btn icon absolute top right @click="remove">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "image-picker",
  props: {
    placeholder: { type: String, default: "Upload Image" },
    multiple: { type: Boolean, default: false },
    src: { type: String, default: "" },
    maxHeight: { type: String, default: "300px" }
  },
  data() {
    return {
      imgSrc: null,
      dragging: false
    };
  },
  computed: {
    hasImageSrc() {
      if (!this.src && !this.imgSrc) return false;
      return this.imgSrc && this.src;
    }
  },
  methods: {
    dragOver() {
      this.dragging = true;
    },
    dragLeave() {
      this.dragging = false;
    },
    getImage(e) {
      const files = e.target.files;
      if (!files) return;
      this.previewImage(files[0]);
      e.target.value = null;
    },
    remove() {
      this.imgSrc = null;
      this.$emit("remove");
    },

    drop(e) {
      let file = e.dataTransfer.files[0];
      this.previewImage(file);
    },
    previewImage(file) {
      if (!file) return;
      const src = URL.createObjectURL(file);
      this.imgSrc = src;
      this.$emit("update", { file, src });
    }
  }
};
</script>

<style lang="scss" scoped>
.empty-container {
  border: 2px dashed #33aada;
  padding: 1rem;
  font-size: 1.5rem;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #33aada;
    color: #fff;
  }
}

.preview-img {
  position: relative;
  text-align: center;
  img {
    max-width: 100%;
    height: auto;
  }
}
</style>
