<template>
  <div class="btn-container">
    <v-btn color="secondary" class="mx-3" @click="companyDialog = true">Add Company</v-btn>
    <v-btn color="secondary" @click="userDialog = true">Add User</v-btn>

    <v-dialog v-model="companyDialog" max-width="80%" persistent>
      <AddCompany @close="companyDialog = false" />
    </v-dialog>

    <v-dialog v-model="userDialog" max-width="500px" persistent>
      <AddUser @close="userDialog = false" />
    </v-dialog>
  </div>
</template>

<script>
import AddCompany from "./add-company.vue";
import AddUser from "./add-user.vue";
export default {
  name: "admin-btn",
  components: {
    AddCompany,
    AddUser,
  },
  data() {
    return {
      companyDialog: false,
      userDialog: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.btn-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 12px;
}
</style>
