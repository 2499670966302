<template>
  <v-app-bar
    app
    color="primary"
    dark
    :clipped-right="$vuetify.rtl"
    :clipped-left="!$vuetify.rtl"
  >
    <div class="d-flex align-center">
      <v-app-bar-nav-icon
        v-if="!$store.state.drawer"
        @click="$store.commit('SET_DRAWER', true)"
      ></v-app-bar-nav-icon>

      <v-img
        alt="easy2employee"
        class="shrink mr-2"
        src="@/assets/logo.png"
        transition="scale-transition"
        width="150"
        @click="$router.push('/')"
      />
    </div>

    <v-spacer></v-spacer>

    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-y
      max-height="400px"
    >
    
      <template v-slot:activator="{ on, attrs }">
        <v-badge class="mt-3" overlap :content="count.toString()" color="secondary" icon>
          <v-icon v-bind="attrs" v-on="on">mdi-bell</v-icon>
        </v-badge>
      </template>

      <v-card>
        <v-list>
          <v-list-item
            v-for="noti in notifications"
            :key="noti._id"
            @click="notificationClicked(noti)"
            color="secondary"
            :input-value="!noti.seen"
            :inactive="true"
            style="backhround-color:blue;"
            
          >
            <v-list-item-content>
              <v-list-item-title>{{ noti.type }}</v-list-item-title>
              <v-list-item-subtitle>{{ noti.slug }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-list v-if="notifications.length === 0">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>No Notifications</v-list-item-title>
              <v-list-item-subtitle
                >Yup don't have any notifications at the
                moment</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>

    <div class=" mx-8 language-dropdown">
      <v-select
        hide-details
        v-model="language"
        :items="languages"
        @change="languageChange"
      ></v-select>
    </div>

    <div class="user-details">
      <p class="text"><v-icon>mdi-account</v-icon> {{ user.first_name }}</p>
      <v-menu transition="slide-y-transition" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item v-for="(item, i) in items" :key="i" @click="item.action">
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <template v-slot:extension v-if="headerExpansion && isAdmin">
      <v-spacer></v-spacer>
      <v-btn color="secondary" rounded class="mx-2" @click="dialog = true">
        {{ $vuetify.lang.t("$vuetify.add_candidate") }}</v-btn
      >
      <v-btn color="secondary" rounded to="/job-post" class="mx-2">{{
        $vuetify.lang.t("$vuetify.post_a_job")
      }}</v-btn>
    </template>

    <v-dialog v-model="dialog" max-width="600px">
      <AddCandidate @close-popup="dialog = false" :key="dialog" />
    </v-dialog>
  </v-app-bar>
</template>

<script>
import { mapState, mapMutations, createNamespacedHelpers } from "vuex";
const notificationModule = createNamespacedHelpers("notification");

import AddCandidate from "@/components/applicant/popup/edit-candidate";
const language = JSON.parse(localStorage.getItem("lang"));
import userRestriction from "@/mixins/user-restriction.js";
export default {
  name: "app-header",
  components: { AddCandidate },
  mixins: [userRestriction],
  data() {
    return {
      language: language.value,
      languages: [
        { text: "English | en", value: "en", rtl: false },
        { text: "Arabic | ar", value: "ar", rtl: true },
      ],
      dialog: false,
      menu: false,
    };
  },
  computed: {
    ...mapState(["user", "headerExpansion"]),
    ...notificationModule.mapState(["loading", "notifications", "count"]),
    items() {
      return [
        {
          title: this.$vuetify.lang.t("$vuetify.profile"),
          id: "Profile",
          action: () => this.$router.push("/profile"),
        },
        {
          title: this.$vuetify.lang.t("$vuetify.logout"),
          id: "Logout",
          action: () => {
            localStorage.clear();
            this.CLEAR_STORE();
            this.$router.push("/login");
          },
        },
      ];
    },
  },
  methods: {
    ...mapMutations(["SET_HEADER_EXPANSION", "CLEAR_STORE"]),
    ...notificationModule.mapActions(["FETCH_NOTIFICATIONS" , 'READ_NOTIFICATION']),
    calculateRoute() {
      const { fullPath } = this.$route;
      if (fullPath === "/" || fullPath === "/application") {
        this.SET_HEADER_EXPANSION(true);
      } else {
        this.SET_HEADER_EXPANSION(false);
      }
    },
    languageChange(value) {
      const lang = this.languages.find((lang) => lang.value === value);
      localStorage.setItem("lang", JSON.stringify(lang));
      this.$vuetify.lang.current = lang.value;
      this.$vuetify.rtl = lang.rtl;
    },
    notificationClicked(noti) {
      const {type , _id , seen , application_id } = noti
      if(!seen) this.READ_NOTIFICATION(_id)
      if(this.user.role !== 0){
        if(type === 'tag'){
          this.$router.push(`/application/${application_id}`)
        }
      }
    },
  },
  watch: {
    $route() {
      this.calculateRoute();
    },
  },
  created() {
    this.calculateRoute();
    this.FETCH_NOTIFICATIONS();
  },
};
</script>

<style lang="scss" scoped>
.language-dropdown {
  max-width: 200px;
}
</style>
